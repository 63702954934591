#Site{
  width: 100%;
  height: 100%;
  background-color: #EFEADD;
}

#IntroPrompt{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

#IntroPromptText{
  font-family: 'VT323', monospace;
  font-size: 4rem;
  animation: bounce 3s infinite ease-in-out;
}

#Intro-About{
  position: absolute;
  top: 25%;
  left: 10%;
  z-index: 2;
}

#About{
  transition: 0.75s all ease-in-out;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 2;
}

#Me{
  width: 100%;
}

#Intro-Projects{
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
}

#Projects{
  transition: 0.75s all ease-in-out;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
}

#ProjArrowLeft{
  transform: scaleX(-1);
}

#Intro-Contact{
  position: absolute;
  top: 25%;
  right: 10%;
  z-index: 2;
}

#Contact{
  transition: 0.75s all ease-in-out;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 2;
}

#ContactForm{
  font-family: 'VT323', monospace;
  font-size: 1.5rem;
  width: 60%;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

#AnimBGContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 600px;
  padding: 2rem;
  margin: auto;
}

#AnimBG{
  margin-top: 50%;
}

.normal{

}

.fuzzed{
  transition: all 0.5s ease-in-out;
  filter: blur(1px) grayscale(40%);
}

.Content{
  position: absolute;
  width: 100%;
  background: rgb(204,204,204);
  background: radial-gradient(circle, rgba(204,204,204,0.8) 20%, rgba(255,255,255,0) 60%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
}

#ContentTextContainer{
  font-family: 'VT323', monospace;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 20%;
  bottom: 0;
  max-width: 350px;
  margin: auto;
}

#AboutTitle{
  font-size: 1rem;
  text-align: center;
}

#AboutText{
  font-size: 1rem;
}

#Title{
  width: 20%;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  z-index: 2;
}

.logos{
  width: 80%;
}

.card{
  width: 25%;
  height: 20%;
  overflow: hidden;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.hidden{
  transition: 0.25s all ease-in-out;
  opacity: 0;
}

.shown{
  transition: 0.25s all ease-in-out;
  opacity: 1;
}

.formEntry{
  width: 100%;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
}

#FormSubmit{
  width: 50%;
  background-color: darkblue;
  color: white;
}

.noselect {
  -webkit-user-select: none;
  -html-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 600px) {

  #ContactForm{
    top: 10%;
  }

  #ContentTextContainer{
    max-width: 300px;
  }

  #Me{
    width: 50%;
  }

  #Title{
    width: 40%;
  }
}