@import url( 'https://fonts.googleapis.com/css?family=Nunito');

$default-height: 40%;
$default-width: 40%;
$max-width: 400px;
$level2-height: $default-height - 8%;
$level2-width: $default-width - 8%;
$level2-max-width: 300px;
$level1-height: $default-height - 2%;
$level1-width: $default-width - 2%;
$level1-max-width: 350px;
$level0-height: $default-height + 5%;
$level0-width: $default-width + 5%;
$level0-max-width: 400px;
$level-2-left: 15%;
$level-1-left: 30%;
$level0-left: 50%;
$level1-left: 70%;
$level2-left: 85%;
$level-2-bs: -.1em .1em .2em #212121;
$level-1-bs: -.2em .2em .4em #212121;
$level2-bs: .1em .1em .2em #212121;
$level1-bs: .2em .2em .4em #212121;
$level0-bs: 0 .4em .8em #212121;

body {
    background-color: #673AB7;
    font-family: 'Nunito';
    overflow: hidden;
}

#carousel {
    height: 100%;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.arrow {
    position: absolute;
    width: 40px;
    text-align: center;
    font-size: 1em;
    cursor: pointer;
    z-index: 20;
    top: 50%;
    transform: translate(0, -50%);
    transition: all 500ms;
    &-right {
        right: 1.5rem;
    }

    &-left {
        left: 0.5rem;
    }
}

.grow:hover, .grow:active{
    transform: scale(1.1);
    transition: 0.5s all ease-in-out
}

.item {
    text-align: center;
    color: white;
    font-size: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: box-shadow 1s,  height 250ms, width 250ms, left 1s, margin-top 1s, line-height 250ms, background-color 1s;
}

.level-2 {
    // box-shadow: $level-2-bs;
    height: $level2-height;
    width: $level2-width;
    max-width: $level2-max-width;
    line-height: $level2-height;
    // background-color: #228291;
    left: $level-2-left;
}

.level-1 {
    // box-shadow: $level-1-bs;
    height: $level1-height;
    width: $level1-width;
    max-width: $level1-max-width;
    line-height: $level1-height;
    // background-color: #6796E5;
    left: $level-1-left;
}

.level0 {
    // box-shadow: $level0-bs;
    height: $level0-height;
    width: $level0-width;
    max-width: $level0-max-width;
    line-height: $level0-height;
    // background-color: #4EC9E1;
    left: $level0-left;
    transform-origin: left top;
}

.level0:hover, .level0:active{
    height: $level0-height + 8%;
    width: $level0-width + 8%;
    transition: 0.5s all ease-in-out;
}

.level1 {
    // box-shadow: $level1-bs;
    height: $level1-height;
    width: $level1-width;
    max-width: $level1-max-width;
    line-height: $level1-height;
    // background-color: #6796E5;
    left: $level1-left;
}

.level2 {
    // box-shadow: $level2-bs;
    height: $level2-height;
    width: $level2-width;
    max-width: $level2-max-width;
    line-height: $level2-height;
    // background-color: #228291;
    left: $level2-left;
}

.level-2,
.level2 {
    z-index: 0;
}

.level-1,
.level1 {
    z-index: 1;
}

.level0 {
    z-index: 2;
}

.left-enter {
    opacity: 0;
    left: $level2-left;
    height: $level2-height - $default-height;
    width: $level2-width - $default-width;
    line-height: $level2-height - 30;

    &.left-enter-active {
        opacity: 1;
        left: $level2-left;
        height: $level2-height;
        width: $level2-width;
        line-height: $level2-height;
        transition: box-shadow 1s,  left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.left-leave {
    opacity: 1;
    left: $level-2-left;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    z-index: -1;

    &.left-leave-active {
        z-index: -1;
        left: $level-2-left;
        opacity: 0;
        height: $level2-height - $default-height;
        width: $level2-width - $default-width;
        line-height: 120px;
        transition: box-shadow 1s,  left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.right-enter {
    opacity: 0;
    left: $level-2-left;
    height: $level2-height - $default-height;
    width: $level2-width - $default-width;
    line-height: $level2-height - 30;

    &.right-enter-active {
        left: $level-2-left;
        opacity: 1;
        height: $level2-height;
        line-height: $level2-height;
        width: $level2-width;
        transition: box-shadow 1s,  left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.right-leave {
    left: $level2-left;
    height: $level2-height;
    opacity: 1;
    line-height: $level2-height;
    width: $level2-width;

    &.right-leave-active {
        left: $level2-left;
        opacity: 0;
        height: $level2-height - $default-height;
        width: $level2-width - $default-width;
        line-height: $level2-height - 30;
        transition: box-shadow 1s,  left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.noselect {
    -webkit-user-select: none;
    -html-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}